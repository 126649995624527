import React, {useEffect, useState} from "react";

import Button from "../Button/Button";
import Popup from "../Popup/Popup";
import {Order} from "../../interfaces/Order";

import LabSlipApiService from "../../services/LabSlipApiService";

//Styles
import styles from "../ApproveButton/ApproveButton.module.scss";
import { useHistory } from "react-router-dom";

type UnmatchResultButtonPropsTypes = {
  text: string,
  selected: Order[],
  onSaved: () => Promise<void>,
  className?: string,
  mobile?: boolean,
  status?:string
};

const UnmatchResultButton = (props: UnmatchResultButtonPropsTypes) => {
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();

  const onApprove = async () => {
    const orderId = props.selected[0].orderId;
    await LabSlipApiService.saveUnmatchResutl(orderId);
    if (props.mobile) {
      setShowPopup(false);
    }
    props.status === "INCOMPLETE" ? history.push("/orders/tests-incomplete") :  history.push("/orders/tests");
    
  };

  useEffect(() => () => {
    setShowPopup(false);
  }, []);

  return <>
    <Button className={props.className ? props.className : ''}
      disabled={!props.selected || !props.selected.length}
      onClick={() => setShowPopup(true)}>{props.text}</Button>
    <Popup show={showPopup} classes={styles.modalApprove} onClose={() => setShowPopup(false)}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalContentTitle}>Unmatch Results</h2>
        <p className={styles.modalContentText}>Are you sure you want to unmatch the result from the order? </p>
        <div className={`${styles.btnBlock} ${styles.btnBlockPopup}`}>
          <Button className={styles.btn} secondary onClick={() => setShowPopup(false)}>Cancel</Button>
          <Button className={styles.btn} onClick={onApprove}>Confirm</Button>
        </div>
      </div>
    </Popup>
  </>
};

export default UnmatchResultButton;
